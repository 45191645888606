import React from "react";
import { Row, Col, Link, Image } from 'grifo-components';
import logo_unicafes from '../assets/images/logo_unicafes.png';
import logo_ministerio from '../assets/images/logo_ministerio.png';
import logo_grifo from '../assets/images/logo_grifo.png';
import logo_roda_bem from '../assets/images/logo_roda_bem.png';
import logo_c2g_log from '../assets/images/logo_c2g_log.png';

const Header = () => {
  return (
    <Row justify="space-between" align="middle" gutter={16}>
      <Col>
        <Link href="http://www.unicafes.org.br" target="_blank">
          <Image width={150} preview={false} src={logo_unicafes} />
        </Link>
      </Col>
      <Col>
        <Link href="https://www.gov.br/cidadania/pt-br" target="_blank">
          <Image width={150} preview={false} src={logo_ministerio} />
        </Link>            
      </Col>
      <Col>          
        <Link href="http://www.grifosistemas.com.br" target="_blank">
          <Image width={150} preview={false} src={logo_grifo} />
        </Link>
      </Col>

      <Col>
        <Link href="https://www.gov.br/cidadania/pt-br" target="_blank">
          <Image width={150} preview={false} src={logo_roda_bem} />
        </Link>
      </Col>
      <Col>
        <Link href="https://www.c2glog.com.br/" target="_blank">
          <Image width={110} preview={false} src={logo_c2g_log} />
        </Link>
      </Col>
    </Row>
  );
};

export default Header;